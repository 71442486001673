import React from 'react';
import './Links.scss';
import {
    VK_LINK,
    LINKED_IN_LINK,
    INSTAGRAM_LINK,
    GITHUB_LINK
} from '../constants/constants';
import vkLogo from './../assets/vk-logo.svg';
import linkedInLogo from './../assets/linked-in-logo.svg';
import instagramLogo from './../assets/instagram-logo.svg';
import githubLogo from './../assets/github-logo.svg';

class Links extends React.Component {
    render() {
        return (
        <div id="links" className="App-links">
            <h1>{this.props.header}</h1>
            <div className="links-items container">
                <></>
                <a href={VK_LINK} target="_blank" rel="noopener noreferrer"><img alt="VK logo" src={vkLogo}/></a>
                <a href={LINKED_IN_LINK} target="_blank" rel="noopener noreferrer"><img alt="Linked In logo" src={linkedInLogo}/></a>
                <a href={INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer"><img alt="Instagram logo" src={instagramLogo}/></a>
                <a href={GITHUB_LINK} target="_blank" rel="noopener noreferrer"><img alt="GitHub logo" src={githubLogo}/></a>
            </div>
        </div>
        );
    }
};

export default Links;
