import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import './Timeline.scss';
import ItemPreview from './ItemPreview'
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const getYears = gql(`query GetYears {
    years
}`);

const GET_MILESTONES = gql(`query getMilestones($lang: Int!) {
    milestones(lang: $lang) {
        id,
        name,
        picturePath,
        year
    }
}`);

export default function Timeline({lang, popupTexts}) {
    const {loading: loadingYears, data: yearsData} = useQuery(getYears);
    const [currentItemPreview, setCurrentItemPreview] = useState(null);
    const {loading, data: milestonesData} = useQuery(GET_MILESTONES, {variables: {lang}});

    const onSelectMilestone = (id) => {
        setCurrentItemPreview(id);
    };

    if (loading  || loadingYears|| !milestonesData.milestones || !yearsData) return null;

    return <div className='timelineRoot'>
        <div id='timeline' className='timelineContainer'>
            {yearsData.years.slice().sort((a, b) => b - a).map(year => (
                <div key={year} className='yearContainer'>
                    <div className='yearHeading'>{year}</div>
                    <div className='milestonesContainer'>
                        {milestonesData.milestones.filter(ms => ms.year === year).map(ms => (
                            <div key={ms.id} className='milestone' onClick={() => {onSelectMilestone(ms.id)}}>
                                <img src={ms.picturePath} alt={ms.name} />
                                <div className='milestoneName'>{ms.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            {currentItemPreview && createPortal(
                <ItemPreview popupTexts={popupTexts} lang={lang} id={currentItemPreview} onClose={() => setCurrentItemPreview(null)} />,
                document.body
            )}
        </div>
    </div>;
};
