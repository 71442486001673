import React from 'react';
import Greeting from './Greeting.jsx';
import Timeline from './Timeline.jsx';
import Links from './Links.jsx';

export default function MainContent({greeting, info, lang, popupTexts, linksHeader}) {
    return (
        <div className="App-main">
            <Greeting
                greeting={greeting}
                info={info}
            />
            <Timeline lang={lang} popupTexts={popupTexts}/>
            <Links header={linksHeader}/>
        </div>
        );
};
