import React from 'react';
import './index.css';
import App from './App';
import { ApolloProvider } from '@apollo/client';
import client from './apollo';
import './assets/fonts/Roboto-Regular.ttf';
import { createRoot } from 'react-dom/client';

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(<ApolloProvider client={client}>
    <App />
</ApolloProvider>);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
