import React from 'react';
import './Header.scss';
import unionJack from './../assets/union-jack.svg';
import tricolor from './../assets/tricolor.svg';

export default function Header({lang, timeline, links, onLanguageChange}) {
    return (
        <header className="App-header container">
            <div className="Header-menu-item">
                <a href="#timeline">{timeline}</a>
                <a href="#links">{links}</a>
            </div>
            <span onClick={onLanguageChange} className="lang-switcher">{lang}<img alt="Flag" src={lang === "RU" ? tricolor : unionJack} className="lang-switcher-flag"/></span>
        </header>
        );
};
