import React from 'react';
import './Footer.scss';
import envelope from './../assets/envelope.svg';
import phone from './../assets/phone.svg';

class Footer extends React.Component {
    render() {
        return (
        <div className="App-footer container">
            <div className="footer-contacts">
                <a href="mailto:gregorypraskura@hotmail.com"><img alt="envelope icon" src={envelope}/>gregorypraskura@hotmail.com</a>
                <a href="tel:7-915-530-82-79"><img className='phone-icon' alt="phone icon" src={phone}/>+7-915-530-82-79</a>
            </div>
            <div className="copyright">
                © {this.props.copyright}, 2020
            </div>
        </div>
        );
    }
};

export default Footer;