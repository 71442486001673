import React, { useState } from 'react';
import './App.css';
import Header from './components/Header.jsx';
import MainContent from './components/MainContent.jsx';
import Footer from './components/Footer.jsx';
import { useQuery, gql } from '@apollo/client';

const GET_SITE_TEXTS = gql`
  query GetSiteTexts {
    siteTexts {
      greeting,
      info,
      linksHeader,
      copyright,
      timeline,
      links,
      lang,
      popupTexts {
        description,
        technologies,
        personalRole,
        linkText
      }
    }
  }
`;

export default function App() {
  const {loading, data} = useQuery(GET_SITE_TEXTS);
  const [language, setLanguage] = useState(0);
  
  const changeLanguage = () => {
    setLanguage(language === 0 ? 1 : 0);
  }

  return (
    !loading && (
        <div className="App">
          <Header
            onLanguageChange={changeLanguage}
            timeline={data.siteTexts[language].timeline}
            links={data.siteTexts[language].links}
            lang={data.siteTexts[language].lang}
          />
          <MainContent
            greeting={data.siteTexts[language].greeting}
            info={data.siteTexts[language].info}
            linksHeader={data.siteTexts[language].linksHeader}
            lang={language}
            popupTexts={data.siteTexts[language].popupTexts}
          />
          <Footer copyright={data.siteTexts[language].copyright}/>
        </div>    
      )
  );
};
