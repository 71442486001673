import React from 'react';
import './Greeting.scss';
import mouse from './../assets//mouse.svg';

class Greeting extends React.Component {
    render() {
        return (
        <div className="App-greeting">
            <h1>{this.props.greeting}</h1>
            <p>{this.props.info}</p>
            <img src={mouse} alt="Scroll down icon"/>
        </div>
        );
    }
};

export default Greeting;